import "./styles.css";
import Navbar from "./components/NavbarComp";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  return (
    <div className="App">
      <Navbar />
{/* Comment */}
    </div>
  );
}

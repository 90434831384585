import "../styles/styles.css";
import React from "react";
import { Button } from "react-bootstrap";
import ReactLogo from "../images/homelogo.svg";
import firstGif from "../images/study.gif"
import srcVector from "../images/image.gif"

import randomFalcon from "../images/randomFlacon.png"
import TeamCard from "./TeamCard.js";
import partnerVectorRight from "../images/partnerVectorRight.png";
import partnerVectorLeft from "../images/partnerVectorLeft.png";
import jump from "../images/jump.png";
import BBALogoWhite from "../images/bbalogowhite.png";
import polygonScatter from "../images/polygon-scatter.svg"

/* Team Photos */
import chris from "../images/team/chris.png";
import chrishagedorn from "../images/team/chrishagedorn.jpg";

import lucas from "../images/team/lucas.jpg";
import rebecca from "../images/team/rebecca.jpg";
import samuel from "../images/team/samuel.jpg";


/*Clear Team Photos */
import beckyclear2 from "../images/team/clear/beckyclear2.png";
import beckyclear from "../images/team/clear/beckyclear.png";
import chrisclear from "../images/team/clear/chrisclear.png";
import lucasclear from "../images/team/clear/lucasclear.png";
import mikeclear from "../images/team/clear/mikeclear.png";
import nicclear from "../images/team/clear/nicclear.png";
import petraclear from "../images/team/clear/petraclear.png";
import samclear from "../images/team/clear/samclear.png";
import vinnyclear from "../images/team/clear/vinnyclear.png";
import gabrielclear from "../images/team/clear/gabrielclear.png";
import otarclear from "../images/team/clear/otarclear.png";


import encode from "../images/encode.png"
import coingecko from "../images/coingecko.png"
import ben from "../images/ben.png"
import thetie from "../images/the_tie.png"
import baf from  "../images/baf.png"
import spawn from  "../images/spawn.png"

import { Footer } from "./Footer"

export const Home = () => {
  return (
    <div>
      <Landing />
      <Goals />
      <Description />
      <Team />
      <Partners />
      <Footer />
    </div>
  );
};

export const Landing = () => {
  return (
    <div className="landing">
      <div className="element-wrapper">
        <h1 className="element-wrapper-h1">Bentley Blockchain Association</h1>
        <h2 className="element-wrapper-h2">
          Providing a borderless blockchain education experience at Bentley
          University
        </h2>
        <div className="main-button">
          <Button href="https://medium.com/@Bentleyblockchain" target="_new" variant="outline-light">
            Learn More
          </Button>
        </div>
      </div>
      {/* <div style={{height: "150px", width: "800px", background: "black", position:"absolute", right: "40px", top:"850px", display: "flex"}}>
        <div style={{background: "orange", width: "160px", height: "150px"}}>
          <img style={{maxHeight: "90%", maxWidth: "100%", padding:"10px"}} src={randomFalcon}/>
        </div>
        <div style={{color: "white", textAlign:"left", padding:"50px"}}>
          <p>Bentley blockchain is one of the <span style={{fontWeight: "bold"}}>largest</span> student DAOs operating at the university level.</p>
        </div>
      </div> */}
    </div>
  );
};

const Goals = () => {
  return (
    <div className="goals">
      <div id="a" className="goals-left-container">
        <div className="goals-text">
          <h2>
            <span className="creating-inspiration">Driving Inspiration</span>,
            Innovation & Education.
          </h2>
        </div>
        <div className="goals-buttons">
          <Button target="_new" href="https://inky-ease-5b3.notion.site/Bentley-Blockchain-Association-8d70a5c98a3347e1a2bfa5e552a524ca" variant="dark" size="lg" style={{ fontSize: "14px", padding: "15px", fontWeight: "bold", borderRadius: "0em" }}>
            Explore Resources
          </Button>
          <Button href="https://discord.gg/7K2MAqYJrQ" variant="outline-dark" target="_new" size="lg" style={{ fontSize: "14px", padding: "15px", borderRadius: "0em" }}>
            Join Discord
          </Button>
        </div>
      </div>

      <div id="b" className="goals-vector">
        <img src={srcVector} alt="Education BBA" />
      </div>
    </div>
  );
};

const Description = () => {
  return (
    <div className="description">
      <div className="description-wrapper">
        <div className="description-main-container">
          <div className="description-header">
            <div className="description-title">
              <h1>What is Bentley Blockchain Association?</h1>
            </div>
            <hr></hr>
          </div>
          <div className="description-container">
            <div className="description-container-item">
              <div className="summmary">
                <h2>BBA</h2>
                <p>
                  Bentley Blockchain Association (BBA) is a group of students
                  and faculty members excited about blockchain technology and
                  the uses of it. We want to inspire everyone at Bentley
                  University to learn about this disruptive technology and
                  innovate wherever they go.
                </p>
              </div>
            </div>
            <div className="description-container-item">
              <div className="vision">
                <h2>Vision</h2>
                <p>
                  Promote economic opportunity, financial freedom, and
                  innovation through blockchain education and career
                  opportunities.
                </p>
              </div>
            </div>

            <div className="description-container-item">
              <div className="Mission">
                <h2>Mission</h2>
                <p>
                We aspire to be trailblazers in web3. Our mission is to provide undergraduate students with the opportunity to learn about blockchain, decentralized finance (DeFi), and cryptocurrency investments. Our goal is to build a place that will prepare students to excel in this evolving business-financial landscape. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Vision = () => {
  return (
    <div className="Vision">
      <div>
        <h2>Vision</h2>
        <p>
          Promote economic opportunity, financial freedom, and innovation
          through blockchain education and career opportunities.
        </p>
      </div>
      <div>
        <h2>Missions</h2>
        <p>
          Our mission is to provide undergraduate students with the opportunity
          to learn about blockchain, decentralized finance (DeFi), and
          cryptocurrency investments. Our goal is to make sure members excel in
          the growing blockchain space by building educational resources that
          can be applied everywhere they go.
        </p>
      </div>
    </div>
  );
};

const Team = () => {
  return (
    <div className="team">
      <div className="team-inner-container">
        <h1>Meet the Team</h1>
        {/* <div className="team-card-top">
          <TeamCard name="Alex Lysyuk" title="Chief Comptroller" icon={Alex} />
          <TeamCard name="Ruta Lysyuk" title="Chief Comptroller" icon={Alex} />
          <TeamCard name="Ruta Lysyuk" title="Chief Comptroller" icon={Alex} />
        </div> */}
        <div className="team-flex-container">
          <div className="row1">
            <div className="team-card-container">
              <TeamCard name="Rebecca Curry" title="Co President" icon={beckyclear} linkden="https://www.linkedin.com/in/beckycurry/" />
            </div>
            <div className="team-card-container">
            </div>
            <div className="team-card-container">
              <TeamCard
                name="Nicolas Saliou"
                title="Co President"
                icon={nicclear}
                linkden="https://www.linkedin.com/in/nicolas-saliou-744630251/"

              />
            </div>
          </div>
          <div className="row2">
          <div className="team-card-container">
              <TeamCard
                name="Samuel Capobianco"
                title="VP of Research and Development"
                icon={samclear}
                linkden="https://www.linkedin.com/in/samuel-capobianco-13836622a/"
              />
            </div>
            <div className="team-card-container">
              {/* <TeamCard
              name="Chris Hagedorn"
              title="Director of Technology"
              icon={chrishagedorn}
              linkden="https://www.linkedin.com/in/christopher-hagedorn/"
            /> */}
            </div>
            <div className="team-card-container">
              <TeamCard
                name="Petra Bajdek"
                title="VP of Marketing"
                icon={petraclear}
                linkden="https://www.linkedin.com/in/petra-bajdek//"
                
              />
            </div>
            
            
          </div>
          <div className="row3">
            <div className="team-card-container">
            </div>
            <div className="team-card-container">
              <TeamCard
                name="Gabriel Krantz"
                title="Research Analyst"
                icon={gabrielclear}
                linkden="https://www.linkedin.com/in/gabriel-krantz-927ba720b/"
              />
            </div>
            <div className="team-card-container">
              <TeamCard
                name="Otar Tchitchinadze"
                title="Research Analyst"
                icon={otarclear}
                linkden="https://www.linkedin.com/in/otar-tchitchinadze-b34720200/"
              />
            </div>
            <div className="team-card-container">
              <TeamCard
                name="Vincent Paratore"
                title="Research Analyst"
                icon={vinnyclear}
                linkden="https://www.linkedin.com/in/vincent-paratore-459126254/"
              />
            </div>
          </div>
          {/* <div className="row4">
          <div className="team-card-container">
            <TeamCard
              name="Artur Lysyuk"
              title="Chief Comptroller"
              icon={Alex}
            />
          </div>
          <div className="team-card-container">
            <TeamCard
              name="Artur Lysyuk"
              title="Chief Comptroller"
              icon={Alex}
            />
          </div>
          <div className="team-card-container">
            <TeamCard
              name="Chris Hagedorn"
              title="Director of Technology"
              icon={Alex}
            />
          </div>
          <div className="team-card-container">
            <TeamCard
              name="Chris Hagedorn"
              title="Director of Technology"
              icon={Alex}
            />
          </div>
          </div> */}
        </div>
      </div>

    </div>
  );
};

const Partners = () => {
  return (
    <div className="partners">
      <img
        className="partner-vector-left"
        src={partnerVectorLeft}
        alt="beautiful vector"
      />
      <div className="partner-container">
        <div className="partner-header">
          <h2>Our Partners</h2>
          <p>Please see our list of partners</p>
        </div>
        <div className="partner-image-container">
          <div className="partner-image-item">
            <a href="https://www.blockchainedu.org/" target="_new"><img className="jump" src={ben} alt="ben" /></a>
          </div>
          <div className="partner-image-item">
            <a href="https://www.encode.club/" target="_new"><img className="jump" src={encode} alt="encode" /></a>
          </div>
          <div className="partner-image-item">
            <a href="https://www.coingecko.com/" target="_new"><img className="jump" src={coingecko} alt="coingecko" /></a>
          </div>
          <div className="partner-image-item">
            <a href="https://www.thetie.io/" target="_new"><img className="jump" style={{ marginTop: "20px" }} src={thetie} alt="the-tie" /></a>
          </div>
          <div className="partner-image-item">
            <a href="https://www.spawn.global/" target="_new"><img className="jump" style={{ marginTop: "20px" }} src={spawn} alt="the-tie" /></a>
          </div>
          <div className="partner-image-item">
            <a href="https://www.blockchainacceleration.org/" target="_new"><img className="jump" style={{ marginTop: "20px" }} src={baf} alt="the-tie" /></a>
          </div>
          {/* <div className="partner-image-item">
            <img className="jump" src={jump} alt="jump" />
          </div>
          <div className="partner-image-item">
            <img className="jump" src={jump} alt="jump" />
          </div> */}
        </div>
      </div>
      <img
        className="partner-vector-right"
        src={partnerVectorRight}
        alt="beautiful vector"
      />
    </div>
  );
};

